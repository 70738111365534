// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import language from './language-picker/index'
import charityProfile from "./charity-profile/index";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  charityProfile,
  language
})

export default rootReducer
