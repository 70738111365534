import * as types from '../../type/charityProfileActionTypes'

export const setGeneralData = (data) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_GENERAL,
            payload: data
        })
    }
}

export const setAttachmentData=(data)=>{
    return dispatch => {
        dispatch({
            type: types.UPDATE_ATTACHMENT,
            payload: data
        })
    }
}

export const setBankData = (data) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_BANK,
            payload: data
        })
    }
}

export const setRelatedCategoryData = (data) => {
    return dispatch=>{
        dispatch({
            type: types.UPDATE_RELATED_CATEGORY,
            payload: data
        })
    }
}

