import apiConfig from "../services/api-config";

export const ACCESS_TOKEN = "2c8dc2f5a6529fdb2536348a55fe4cd2t0"
//donor
// export const BASIC_AUTH = "cmVnaXN0ZXJlZF9kb25vcjo="
//charity
export const BASIC_AUTH = "Y2hhcml0eTo="
export const BASIC_DONOR_AUTH = "cmVnaXN0ZXJlZF9kb25vcjo="
export const REFRESH_TOKEN = "6c66b0";
export const VERIFY_CODE = "RtEw67ZUI6bg";
export const USER_OBJECT = "USER_OBJECT";
export const USER_DATA = 'userData';

export const ROUTE_PATH = "";
//export const BASIC_AUTH = "";
export const HOME_PATH = `${ROUTE_PATH}/home`;
export const LOGIN_PATH = `${ROUTE_PATH}/login`;
export const REGISTRATION_PATH = `${ROUTE_PATH}/register`;
export const FORGOT_PASSWORD_PATH = `${ROUTE_PATH}/reset-password`;
export const RESET_PASSWORD_PATH = `${ROUTE_PATH}/forgot-password`;
export const COMPLETED_REGISTRATION = `${ROUTE_PATH}/register-complete`;
export const ABOUT_YOU_PATH = `${ROUTE_PATH}/about-you`;
export const VALIDATE_ORGANIZATION_PATH = `${ROUTE_PATH}/validate-organization`;
export const BANK_INFORMATION_PATH = `${ROUTE_PATH}/bank-information`;
export const HEADER = `${ROUTE_PATH}/header`;
export const RESEND_EMAIL_PATH = `${ROUTE_PATH}/resend-email`;
export const SELECTED_PROGRAM_PATH = `${ROUTE_PATH}/selected-program`;
export const CHARITY_PROGRAM_PATH = `${ROUTE_PATH}/charity-programs`;
export const DASHBOARD_PATH = `${ROUTE_PATH}/dashboard`;
export const MY_PROGRAMS_PATH = `${ROUTE_PATH}/my-programs`;
export const PAYMENT_SLIPS_PATH = `${ROUTE_PATH}/payment-slips`;
export const REPORTS_PATH = `${ROUTE_PATH}/reports`;
export const INTEREST_PATH = `${ROUTE_PATH}/interest`;
export const ERROR_PATH = `${ROUTE_PATH}/error`;
export const OTP_PATH = `${ROUTE_PATH}/otp`;
export const PACKAGE_REPORTS_PATH = `${ROUTE_PATH}/package-reports`;

export const ALL_CATEGORIES_PATH = `${ROUTE_PATH}/all-categories`;
export const SELECTED_CATEGORY_PATH = `${ROUTE_PATH}/category`;
export const MY_RECOMMENDATION_PATH = `${ROUTE_PATH}/my-recommendation`;

export const CREATE_CHARITY_PROGRAM_PATH = `${ROUTE_PATH}/create-charity-program`;
export const UPDATE_CHARITY_PROGRAM_PATH = `${ROUTE_PATH}/update-charity-program`;
export const CHARITY_PROFILE_PATH = `${ROUTE_PATH}/charity-profile`;


export const DONOR_VERIFY_PATH = `${ROUTE_PATH}/donor-verify`;
export const CHARITY_TEST_PHOTO = `${ROUTE_PATH}/charity-test-photo`;


export const DONOR_PORTAL_URL = "https://karuna.lk/login"
export const DONOR_PORTAL_HOME = "https://karuna.lk/home";
export const RESOURCE_SERVER_URL = "https://resources.karuna.lk";
export const PRIVACY_POLICY_URL = `${RESOURCE_SERVER_URL}/pdf/Privacy_Policy.pdf?t=${new Date().getTime()}`;
export const TERM_AND_CONDITION_URL = `${RESOURCE_SERVER_URL}/pdf/Terms_and_Conditions.pdf?t=${new Date().getTime()}`;
export const CHARITY_PROGRAM_BASE_URL = `${apiConfig.serverUrl}/${apiConfig.basePath}/program`;
export const CEYENTRA_URL = "https://ceyentra.com";
export const DIALOG_URL = "https://www.dialog.lk/";

export const LAN_ENGLISH = "en";
export const LAN_SINHALA = "si";
export const LAN_TAMIL = "ta";

export const LAN = 'lan';