import * as type from '../../type/charityProfileActionTypes'
import {setAttachment} from "../../actions/charity-profile";
// ** Initial State
const initialState = {
    general: {
        isOrganization: '',
        contactName: '',
        email: '',
        mobile: '',
        organizationName: '',
        organizationLogoURL: null,
        address: '',
        description: '',
    },
    bankInformation: {
        beneficiaryName: '',
        accountNo: '',
        branchName: '',
        branchCode: '',
        bankName: '',
        bankCode: ''
    },
    attachment:{
        requestLetter: '',
        charityRegistrationDocument: '',
        constitutionOfTheCharity: '',
        detailsOfTheTrusteesAndMembers: '',
        otherDocuments: ''
    },
    relatedCategory: {
        tableList: [''],
    }
}

const charityReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.UPDATE_GENERAL:
            return {
                ...state,
                general: {
                    ...state.general,
                    ...action.payload,
                }
            }
        case type.UPDATE_BANK: {
            return {
                ...state,
                bankInformation: {
                    ...state.bankInformation,
                    ...action.payload,
                }
            }
        }
        case type.UPDATE_RELATED_CATEGORY:
            return {
                ...state,
                relatedCategory: {
                    ...state.relatedCategory,
                    ...action.payload,
                }
            }
        case type.UPDATE_ATTACHMENT:
            return {
                ...state,
                attachment: {
                    ...state.attachment,
                    ...action.payload,
                }
            }
        default:
            return state
    }
}

export default charityReducer;
